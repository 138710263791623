import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"

import {I18nComponent} from "@/components/I18nComponent"
import {BIconCloudDownload, BIconXCircleFill} from "bootstrap-vue"
import TableComponent from "@/components/TableComponent"

import {FieldLike, TableRow} from "@/utils/vue-bootstrap-types"
import {FileData} from "@/types"
import CheckBox from "@/components/form/CheckBox"
import {BackendUrls} from "@/constants/APIconstants"


@Component({
  name: 'AttachmentsTable',
  components: {
    BIconCloudDownload,
    BIconXCircleFill
  }
})
export default class extends I18nComponent {
  @Prop(Boolean) public readonly publicOnly?: boolean
  @Prop(Array) public readonly value!: FileData[]

  private get tableFields(): FieldLike[] {
    return [
      {
        key: 'fileName',
        label: this.translation('table_col_file_name')
      },
      {
        key: 'checkbox',
        label: this.translation('table_col_visibility'),
        class: this.publicOnly ? 'checkbox-column d-none' : 'checkbox-column'
      },
      {
        key: 'download',
        label: this.translation('table_col_download')
      }
    ]
  }

  public render(): VNode {
    return (
      <div class="w-100 px-md-3 mt-5">
        <TableComponent
          bordered={false}
          borderless
          hover={false}
          fields={this.tableFields}
          items={this.value}
          tbody-tr-class="list-row"
          small
          striped={false}
          tableClass="app-list-table"
          wrapper-class="mb-0"
          scopedSlots={{
            "head(download)": (row: TableRow<FileData>) => {
              return (
                <div class="sr-only">{row.field.label}</div>
              )
            },
            "cell(fileName)": (row: TableRow<FileData>) => {
              return (
                <div class="d-flex mb-1 mb-md-2">
                  <b-link
                    aria-label={this.translation('btn_delete')}
                    class="text-danger mr-2"
                    target={'_self'}
                    onClick={() => this.$emit('removeFile', row.item)}
                  >
                    <b-icon-x-circle-fill aria-hidden="true" class="d-block app-icon-lg m-0" />
                  </b-link>
                  <b-link class="font-weight-bold" href={`${BackendUrls.urlApi}${BackendUrls.file}/${encodeURIComponent(row.item.id)}`}>
                    {row.item.fileName}
                  </b-link>
                </div>
              )
            },
            "cell(checkbox)": (row: TableRow<FileData>) => {
              return (
                <CheckBox
                  checked={row.item.public}
                  class="mb-2"
                  checkBoxClass="mb-0"
                  switch
                  onChange={() => this.$emit('publishChange', row.item)}
                >
                  <template slot="label">
                    {this.translation(row.item.public ? 'text_attachments_published' : 'text_attachments_unpublished')}
                  </template>
                </CheckBox>
              )
            },
            "cell(download)": (row: TableRow<FileData>) => {
              return (
                <b-button class="mb-2 text-nowrap" href={`${BackendUrls.urlApi}${BackendUrls.file}/${encodeURIComponent(row.item.id)}`} variant="outline-primary">
                  <b-icon-cloud-download aria-hidden="true" class="app-icon-lg mr-2" />
                  {this.translation('btn_download')}
                </b-button>
              )
            }
          }}
        />
      </div>
    )
  }
}