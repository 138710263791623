import {Component, Prop, Watch} from "vue-property-decorator"
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin"

import {servicesStore} from "@/_modules/service/store/services"

import {ErrorCodes} from "@/constants/APIconstants"
import ApplicationConfiguration from "@/constants/ApplicationConfiguration"
import {BasicErrorHandler} from "@/utils/errorHandler"
import * as Error from "@/utils/errors"
import {FileData, FilePublishStatePayload} from "@/types"
import AttachmentsTable from "@/components/AttachmentsTable";
import AppIconLoading from "./icons/AppIconLoading"


export const attachmentsErrors = {
  maxFilesAmount: "err_max_files_amount_reached",
  maxFileSize: "err_file_size_too_big",
  nameTooLong: "err_file_name_too_long",
  alreadyExist: "err_file_already_exists",
  invalidUploadToken: "err_file_upload_token_used_by_another_user",
  operationNotAuthorized: "err_operation_not_authorized"
  //fileNotFound: "FileAttachmentNotFound"
}

export class AttachmentsErrorHandler extends BasicErrorHandler {

  protected async handleBackendError(e: Error.BackendError): Promise<void> {
    switch(e.code) {
      case ErrorCodes.FileNameAlreadyExists:
        this.errorMessageKey = attachmentsErrors.alreadyExist
        break
      case ErrorCodes.FileUploadTokenIsUsedByAnotherUser:
        this.errorMessageKey = attachmentsErrors.invalidUploadToken
        break
      case ErrorCodes.OperationIsNotAuthorized:
        this.errorMessageKey = attachmentsErrors.operationNotAuthorized
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}
const attachmentsErrorHandler = new AttachmentsErrorHandler()

@Component({name: 'Attachments'})
export default class extends BackendClientMixin {

  @Prop(Array) public readonly value!: FileData[]
  @Prop(String) public readonly uploadToken!: string
  @Prop({type: String, default: ''}) public readonly placeholder!: string
  @Prop(Boolean) public readonly publicOnly?: boolean
  @Prop(Boolean) public readonly messenger?: boolean
  @Prop({type: Boolean, required: false, default: false}) public readonly disabled?: boolean
  @Prop({type: String, required: false, default: ''}) public readonly browseText!: string
  @Prop(String) public readonly uploadDescription?: string
  @Prop(Boolean) public readonly descriptionForDemand?: boolean

  private files: File[] = []
  private isUploading: boolean = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //private url: any = null

  private isContainError(file: File): boolean {
    switch (true) {
      case (this.files.length > ApplicationConfiguration.maxAttachmentsAmount || this.value.length > ApplicationConfiguration.maxAttachmentsAmount || this.files.length + this.value.length > ApplicationConfiguration.maxAttachmentsAmount):
        this.$emit('attachmentsError', attachmentsErrors.maxFilesAmount)
        return true
      case file.size > ApplicationConfiguration.maxUploadableFileSize:
        this.$emit('attachmentsError', attachmentsErrors.maxFileSize)
        return true
      case file.name.length > ApplicationConfiguration.maxFileNameLength:
        this.$emit('attachmentsError', attachmentsErrors.nameTooLong)
        return true
      case (Array.from(this.value.values()).map((v) => v.fileName).includes(file.name)):
        this.$emit('attachmentsError', attachmentsErrors.alreadyExist)
        return true
      default: return false
    }
  }

  @Watch("files")
  public onFilesChange(): void {
    for (let i = 0; i < this.files.length; i++) {
      if (!this.isContainError(this.files[i])) {
        this.$emit('fileUpload', this.files[i])
        this.$emit('fileUploadInProgress', true)
        this.isUploading = true;
        const formData = new FormData()
        formData.append('file', this.files[i], this.files[i].name)
        formData.append('public', this.publicOnly ? 'true' : 'false')
        formData.append('uploadToken', this.uploadToken)
        this.withRequest(servicesStore.uploadFile(formData), attachmentsErrorHandler).then((resp) => {
          this.$emit('input', resp)
          this.$emit('fileUploadInProgress', false)
          this.isUploading = false;
        })
      } else {
        break
      }
    }
  }

  private async onRemoveFile(value: FileData): Promise<void> {
    await this.withRequest(servicesStore.deleteFileById(value.id))
    this.files.splice(0, this.files.length)
    this.value.splice(this.value.indexOf(value), 1)
  }

  public trimString(fileName: string): string {
    return fileName.substring(0, ApplicationConfiguration.fileNameStringFromStart) + '...' + fileName.substring(fileName.length - ApplicationConfiguration.fileNameStringFromEnd, fileName.length)
  }

  public async onPublishChange(file: FileData): Promise<void> {
    file.public = !file.public
    const payload: FilePublishStatePayload = {
      fileId: file.id,
      visibility: file.public ? 'publish' : 'unpublish'
    }
    await this.withRequest(servicesStore.setFileVisibility(payload)).catch(() => {
      file.public = !file.public
    })
  }

  private get browseLabel(): string {
    if (this.browseText?.length > 0) {
      return this.browseText;
    }

    return this.translation('btn_services_upload_file');
  }

  /*private preparePreview(e: Event): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const target = e.target as any
    this.url = URL.createObjectURL(target.files[0])
  }*/

  public render(): VNode {
    return (
      <div>
        <div class="position-relative">
          <b-form-file
            onChange={(e: Event) => this.$emit('changeFiles', e)}
            disabled={this.disabled}
            v-model={this.files}
            multiple={true}
            placeholder={this.placeholder}
            drop-placeholder={this.placeholder}
            file-name-formatter={() => ''}
            browse-text={this.browseLabel}
            class={{'is-uploading': this.isUploading}}
          />
          {this.isUploading && 
            <div class="file-uploading" data-label={this.browseLabel}>
              <AppIconLoading aria-hidden="true" class="app-icon-lg" />
            </div>
          }
        </div>
        {this.uploadDescription &&
          <small class="text-muted">{this.translation(this.uploadDescription)}</small>
        }
        {!this.messenger && this.descriptionForDemand &&
          <b-row class="mt-3">
            <b-col class="text-muted">
              <small>
                {this.translation('msg_file_attachments_sensitive_information_attention')}
              </small>
            </b-col>
          </b-row>
        }
        {!this.messenger && this.value.length > 0 &&
          <AttachmentsTable
            value={this.value}
            onRemoveFile={(v: FileData) => this.onRemoveFile(v)}
            onPublishChange={(v: FileData) => this.onPublishChange(v)}
            public-only={this.publicOnly}
          />
        }
      </div>
    )
  }
}
