import Vue from "vue"
import {Component} from "vue-property-decorator"
import {VNode} from "vue"

@Component({name: 'AppIconLoading'})
export default class extends Vue {
  public render(): VNode {
    return (
      <svg viewBox="8 8 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="loading" xmlns="http://www.w3.org/2000/svg" fill="none" class="app-icon app-icon-loading">
        <circle cx="16" cy="16" r="6" fill="none" stroke="currentColor" stroke-width="1" stroke-miterlimit="10"></circle>
      </svg>
    )
  }
}
