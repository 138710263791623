import {VNode} from "vue"
import {Component, Model, Prop} from "vue-property-decorator"

import {AbstractInputComponent, AbstractInputPropsType} from "@/components/form/AbstractInputComponent"

type ValueType = string | number | boolean | undefined

interface PropsType extends AbstractInputPropsType<ValueType> {
  checked?: boolean;
  switch?: boolean;
  size?: string;
  stacked?: boolean;
  inline?: boolean;
  checkBoxClass?: string;
}

@Component({name: "CheckBox"})
export default class extends AbstractInputComponent<ValueType, PropsType> implements PropsType {

  //@Prop({type: Boolean, required: false, default: false}) public readonly checked!: boolean
  @Prop({type: [String, Number, Boolean], required: false, default: true}) public readonly value!: ValueType
  @Model('input', {type: Boolean, required: false, default: false}) public readonly checked!: boolean
  @Prop(Boolean) public readonly switch?: boolean
  @Prop({type: String, required: false, default: 'md'}) public readonly size?: string
  @Prop(Boolean) public readonly uncheckedValue?: boolean
  @Prop(Boolean) public readonly stacked?: boolean
  @Prop(Boolean) public readonly inline?: boolean
  @Prop({type: String, required: false, default: ''}) public readonly checkBoxClass?: string

  public isEmpty(): boolean {
    // Checkbox can't be empty
    return false
  }

  public render(): VNode {
    // TODO: Plaintext must show selected value as a text
    return (
      <b-form-group
        description={this.description}
        state={this.state}
        invalid-feedback={this.invalidMessage}
      >
        <b-form-checkbox
          id={this.id}
          name={this.name}
          required={this.required}
          disabled={this.disabled}
          value={this.value}
          checked={this.checked}
          size={this.size}
          onInput={this.onInput}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          switch={this.switch}
          stacked={this.stacked}
          class={this.checkBoxClass}
        >
          {this.$slots.label === undefined ? this.label : this.$slots.label}
        </b-form-checkbox>
      </b-form-group>
    )
  }
}
